import { Combobox } from '@headlessui/react';
import { getAddressAutocompleteHits, getAddressAutocompleteResult } from '@providers/address';
import { useEffect, useRef, useState } from 'react';
import AddressLookupResult from '../AddressLookupResult';
import HighlightedLabel from './HighlightedLabel';

const countryTranslations = {
  België: 'Belgium',
  Denemarken: 'Denmark',
  Duitsland: 'Germany',
  Frankrijk: 'France',
  Luxemburg: 'Luxembourg',
  Noorwegen: 'Norway',
  Oostenrijk: 'Austria',
  Spanje: 'Spain',
  'Verenigd Koninkrijk': 'United Kingdom',
  Zwitserland: 'Switzerland',
};

const AutocompleteHit = ({
  hit,
  country,
  address,
  setAddress,
  setShowResult,
  setAutocompleteHits,
  setOpen,
  setSelected,
  isValid,
  setIsValid,
}) => {
  const { value, label, description, precision, context, highlights } = hit;

  async function onClick() {
    const translatedCountry = countryTranslations[country] || country;
    if (precision === 'Address') {
      const response = await getAddressAutocompleteResult(context);
      setAddress({
        ...address,
        city: response.data.city,
        street: response.data.street,
        postcode: response.data.postcode,
        number: response.data.number,
        numberSuffix: response.data.number_suffix,
        mailLines: response.data.mail_lines,
        isPoBox: false,
      });
      setIsValid({ ...isValid, address: true });
      setShowResult(true);
      setSelected(label);
      setOpen(false);
    } else {
      const response = await getAddressAutocompleteHits(
        translatedCountry,
        value,
        context,
        precision,
      );
      if (response && response.status === 200 && response.data.matches.length > 0) {
        setAutocompleteHits(response.data.matches);
        setSelected(label);
      }
    }
  }

  return (
    <button
      type="button"
      className="flex w-full items-center justify-start gap-3 p-4 text-left hover:bg-accent hover:text-white/90"
      onClick={onClick}
    >
      {label ? (
        <>
          <HighlightedLabel label={label} highlights={highlights} />
          <div className="text-xs leading-6">{description}</div>
        </>
      ) : (
        <HighlightedLabel label={label} highlights={highlights} />
      )}
    </button>
  );
};

function AddressByAutocomplete({
  country,
  address,
  setAddress,
  isBillingAddress,
  isValid,
  setIsValid,
}) {
  const [autocompleteHits, setAutocompleteHits] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);
  const debounceTimer = useRef(null);
  const [selected, setSelected] = useState(null);

  async function handleRequest() {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(async () => {
      const translatedCountry = countryTranslations[country] || country;
      const response = await getAddressAutocompleteHits(translatedCountry, query);
      if (response && response.status === 200 && response.data.matches.length > 0) {
        setAutocompleteHits(response.data.matches);
        setOpen(true);
      } else {
        setAutocompleteHits([]);
      }
    }, 250);
  }

  useEffect(() => {
    if (query.length > 1) {
      handleRequest();
    } else {
      setAutocompleteHits([]); // Clear hits if query is too short
    }
  }, [query]);

  return (
    <div className="flex flex-col gap-6">
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <Combobox.Label
            htmlFor="query"
            className="absolute -top-2 left-3 inline-block bg-white px-1 text-xs"
          >
            Zoeken
          </Combobox.Label>
          <Combobox.Input
            id="query"
            type="search"
            placeholder="Zoek naar een adres"
            onChange={(e) => setQuery(e.target.value)}
            className="block w-full rounded-md px-4 py-5 font-secondary text-sm tracking-wide text-primary-dark shadow-sm outline-none ring-1 ring-inset ring-[#E1E1E1] placeholder:text-primary-dark/30 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
          />
          {open && (
            <Combobox.Options
              static
              className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg"
            >
              {autocompleteHits.map((hit, index) => (
                <Combobox.Option key={index} value={hit}>
                  <AutocompleteHit
                    key={index}
                    hit={hit}
                    country={country}
                    address={address}
                    setAddress={setAddress}
                    setAutocompleteHits={setAutocompleteHits}
                    setShowResult={setShowResult}
                    setSelected={setSelected}
                    setOpen={setOpen}
                    isValid={isValid}
                    setIsValid={setIsValid}
                  />
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
      {showResult && address.mailLines && (
        <AddressLookupResult
          country={address.country}
          mailLines={address.mailLines}
          isBillingAddress={isBillingAddress}
        />
      )}
    </div>
  );
}

export default AddressByAutocomplete;
