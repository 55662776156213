import Icon from '@elements/Icon';
import { Listbox, Transition } from '@headlessui/react';
import { capitalise } from '@helpers/capitalise';
import { classNames } from '@helpers/classNames';
import { Fragment, useEffect, useState } from 'react';

export default function ListBox({
  label,
  choices,
  errors,
  name,
  onChange,
  isValid,
  value,
  isDynamicList,
  initialValue,
  customPosition,
}) {
  const [selected, setSelected] = useState(
    initialValue ? initialValue : choices.find((choice) => choice.value === value) || choices[0],
  );

  const handleChange = (newValue) => {
    setSelected(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    const newSelection = choices.find((choice) => choice.value === value);
    if (newSelection && newSelection.value !== selected.value) {
      setSelected(newSelection);
    }
  }, [value, choices, selected.value]);

  return (
    <div className={classNames(customPosition ? customPosition : '')}>
      <Listbox
        as="div"
        className={classNames(
          'relative inline-block w-full text-primary-dark',
          errors ? 'text-red-500' : 'text-accent',
        )}
        value={selected}
        onChange={handleChange}
        name={name}
      >
        {({ open }) => (
          <div>
            <label
              htmlFor={name}
              className="absolute -top-2 left-3 inline-block bg-white px-1 font-primary text-xs font-light tracking-wide"
            >
              {label}
            </label>
            <Listbox.Button
              className={classNames(
                'block w-full rounded-md border-0 px-4 py-5 font-secondary tracking-wide shadow-sm outline-none ring-1 ring-inset ring-[#E1E1E1] placeholder:text-primary-dark/30 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6',
                errors
                  ? 'text-red-500 ring-red-500'
                  : isValid
                    ? 'ring-green-500'
                    : 'focus-within:text-accent',
                'cursor-pointer',
              )}
            >
              <div className="flex items-center justify-between text-sm text-primary-dark">
                <div>
                  {initialValue
                    ? capitalise(initialValue.toString().replace(/_/g, ' ').toLowerCase())
                    : isDynamicList
                      ? selected.label
                      : selected.value}
                </div>
                <Icon iconName={open ? 'chevron-up' : 'chevron-down'} />
              </div>
            </Listbox.Button>
            <Transition
              as={Fragment}
              enter="transition ease-in duration-200"
              leave="transition ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className="absolute z-20 mt-1 w-full overflow-y-auto rounded-md bg-white py-1 shadow-lg focus:outline-none"
                style={{ maxHeight: '250px' }}
              >
                {choices.map((choice, index) => (
                  <Listbox.Option
                    key={index}
                    className={classNames(
                      'ui-selected:bg-accent ui-selected:text-functional-contrast w-full py-2 pl-10 pr-4 font-primary text-sm font-light text-primary-dark hover:bg-accent hover:text-functional-contrast',
                      'cursor-pointer',
                    )}
                    value={choice}
                  >
                    {isDynamicList ? choice.label : choice.value}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  );
}
