function HighlightedLabel({ label, highlights }) {
  const getHighlightedLabel = (label, highlights) => {
    let highlightedLabel = '';
    let lastIndex = 0;

    if (highlights && highlights.length > 1) {
      highlights.forEach(([start, end]) => {
        highlightedLabel += label.substring(lastIndex, start);
        highlightedLabel += `<em class="font-bold">${label.substring(start, end)}</em>`;
        lastIndex = end;
      });

      highlightedLabel += label.substring(lastIndex);

      return highlightedLabel;
    } else {
      return label;
    }
  };

  const highlightedLabel = getHighlightedLabel(label, highlights);

  return (
    <div className="text-sm leading-6" dangerouslySetInnerHTML={{ __html: highlightedLabel }} />
  );
}

export default HighlightedLabel;
