import Icon from '@elements/Icon';
import clsx from 'clsx';

function AddressLookupResult({ country, mailLines, isBillingAddress }) {
  return (
    <ul
      className={clsx(
        'rounded-md p-6 font-primary text-sm font-bold tracking-wide shadow-md',
        isBillingAddress ? 'bg-accent-2 text-sky-800' : 'bg-accent-4 text-primary-dark/80',
      )}
    >
      <div className="flex items-start justify-between">
        <div>
          {mailLines.map((line, index) => (
            <li key={index}>{line}</li>
          ))}
          <li>{country}</li>
        </div>
        <Icon
          iconName={isBillingAddress ? 'invoice' : 'shipping'}
          color={isBillingAddress ? 'text-sky-800' : 'text-primary-dark/80'}
          size={isBillingAddress ? 'xl' : 'lg'}
        />
      </div>
    </ul>
  );
}

export default AddressLookupResult;
