import Icon from '@elements/Icon';
import { classNames } from '@helpers/classNames';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { baseStyle, buttonStyle, iconStyle } from './buttonConstants';

function entityChecker(a, b) {
  if (a !== '' && b !== '') {
    return true;
  } else {
    return false;
  }
}

const LinkButton = ({
  buttonType,
  label,
  disabled,
  href,
  target,
  icon,
  iconPosition,
  overrideClass,
  onClick,
}) => {
  const hasIcon = entityChecker(icon, iconPosition);
  return (
    <Link
      onClick={onClick}
      href={href}
      target={target}
      disabled={disabled}
      className={classNames(
        baseStyle,
        buttonStyle[buttonType],
        // So basically if iconRight is present we add a or flex-row-reverse to the button
        // Otherwise it will be '' or null. Below is a shorthand notation for that statement
        !!hasIcon && iconStyle[iconPosition],
        overrideClass,
      )}
    >
      {label}
      {!!hasIcon && <Icon iconName={icon}></Icon>}
    </Link>
  );
};

// PropTypes that help dev's to know what props are available to render component
// TODO Perhaps combine icon and iconPosition into one prop of type array as both are needed when an Icon is used.
LinkButton.propTypes = {
  buttonType: PropTypes.oneOf(['primary', 'secondary', 'tertiary']).isRequired,
  label: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(['right', 'left']),
  overrideClass: PropTypes.string,
};

// Default prop types if certain values are omitted
LinkButton.defaultProps = {
  buttonType: 'primary',
  label: '',
  href: '',
  target: '',
  icon: '',
  iconPosition: 'right',
  overrideClass: '',
};

export default LinkButton;
