import * as Sentry from '@sentry/browser';
import { clientApi } from './clientApi';

export async function getAddressByPostcode(postcode, number, numberSuffix) {
  try {
    const params = { postcode: postcode, number: number };
    if (numberSuffix) {
      params.number_suffix = numberSuffix;
    }
    return await clientApi('/utilities/address/postcode', 'GET', params);
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function getAddressAutocompleteHits(country, query, context, precision) {
  try {
    const params = { country: country, query: encodeURIComponent(query) };
    if (context && precision) {
      params.context = context;
      params.precision = precision;
    }
    return await clientApi('/utilities/address/autocomplete/search', 'GET', params);
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function getAddressAutocompleteResult(context) {
  try {
    const params = { context: context };
    return await clientApi('/utilities/address/autocomplete/result', 'GET', params);
  } catch (e) {
    Sentry.captureException(e);
  }
}
