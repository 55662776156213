import { useEffect, useState } from 'react';
import ListBox from '../ListBox';
import AddressByAutocomplete from './AddressByAutocomplete';
import AddressByManualInput from './AddressByManualInput/index.jsx';
import AddresssByPostcode from './AddressByPostcode/index.jsx';
import countries, { getAddressType } from './helpers';

function AddressType(countryId, address, setAddress, isBillingAddress, isValid, setIsValid) {
  switch (countryId) {
    case 'Nederland':
      return (
        <AddresssByPostcode
          address={address}
          setAddress={setAddress}
          isBillingAddress={isBillingAddress}
          isValid={isValid}
          setIsValid={setIsValid}
        />
      );

    case 'België':
    case 'Denemarken':
    case 'Duitsland':
    case 'Frankrijk':
    case 'Luxemburg':
    case 'Noorwegen':
    case 'Oostenrijk':
    case 'Spanje':
    case 'Verenigd Koninkrijk':
    case 'Zwitserland':
      return (
        <AddressByAutocomplete
          country={countryId}
          address={address}
          setAddress={setAddress}
          isBillingAddress={isBillingAddress}
          isValid={isValid}
          setIsValid={setIsValid}
        />
      );

    default:
      return (
        <AddressByManualInput
          address={address}
          setAddress={setAddress}
          isValid={isValid}
          setIsValid={setIsValid}
        />
      );
  }
}

function Address({ register, errors, isBillingAddress, isValid, setIsValid, address, setAddress }) {
  const [countryId, setCountryId] = useState(countries[0].value);

  const getDefaultAddress = (countryId) => ({
    type: getAddressType(isBillingAddress),
    city: '',
    country: countryId,
    street: '',
    postcode: '',
    number: '',
    numberSuffix: '',
    mailLines: '',
    isPoBox: false,
  });

  useEffect(() => {
    if (address) {
      setCountryId(address.country);
    } else {
      setAddress(getDefaultAddress(countryId));
    }
  }, [address, countryId, setAddress]);

  const handleListBoxChange = (selectedCountry) => {
    setCountryId(selectedCountry.value);
    setAddress(getDefaultAddress(selectedCountry.value));
  };

  return (
    <div className="flex gap-16">
      <div className="flex w-full flex-col gap-6">
        <ListBox
          choices={countries}
          label={'Land'}
          register={register}
          errors={errors}
          value={countryId}
          initialValue={countryId}
          onChange={handleListBoxChange}
        />
        <div>
          {AddressType(countryId, address, setAddress, isBillingAddress, isValid, setIsValid)}
        </div>
      </div>
    </div>
  );
}

export default Address;
