const countries = [
  { id: 'Netherlands', value: 'Nederland' },
  { id: 'Andorra', value: 'Andorra' },
  { id: 'Aruba', value: 'Aruba' },
  { id: 'Belgium', value: 'België' },
  { id: 'Bulgaria', value: 'Bulgarije' },
  { id: 'Dutch Caribbean', value: 'Caribisch Nederland' },
  { id: 'Cyprus', value: 'Cyprus' },
  { id: 'Curacao', value: 'Curaçao' },
  { id: 'Denmark', value: 'Denemarken' },
  { id: 'Germany', value: 'Duitsland' },
  { id: 'Estonia', value: 'Estland' },
  { id: 'Finland', value: 'Finland' },
  { id: 'France', value: 'Frankrijk' },
  { id: 'Greece', value: 'Griekenland' },
  { id: 'Hungary', value: 'Hongarije' },
  { id: 'Ireland', value: 'Ierland' },
  { id: 'Italy', value: 'Italië' },
  { id: 'Croatia', value: 'Kroatië' },
  { id: 'Latvia', value: 'Letland' },
  { id: 'Lithuania', value: 'Litouwen' },
  { id: 'Luxembourg', value: 'Luxemburg' },
  { id: 'Malta', value: 'Malta' },
  { id: 'Monaco', value: 'Monaco' },
  { id: 'Norway', value: 'Noorwegen' },
  { id: 'Austria', value: 'Oostenrijk' },
  { id: 'Poland', value: 'Polen' },
  { id: 'Portugal', value: 'Portugal' },
  { id: 'Romania', value: 'Roemenië' },
  { id: 'San Marino', value: 'San Marino' },
  { id: 'Slovenia', value: 'Slovenië' },
  { id: 'Slovakia', value: 'Slowakije' },
  { id: 'Spain', value: 'Spanje' },
  { id: 'Sint Maarten', value: 'Sint Maarten' },
  { id: 'Czech Republic', value: 'Tsjechië' },
  { id: 'United Kingdom', value: 'Verenigd Koninkrijk' },
  { id: 'Sweden', value: 'Zweden' },
  { id: 'Switzerland', value: 'Zwitserland' },
  { id: 'Vatican City', value: 'Vaticaanstad' },
];

const addressType = {
  billing: 'BILLING_ADDRESS',
  shipping: 'SHIPPING_ADDRESS',
};

export function getAddressType(isBillingAddress) {
  if (isBillingAddress) {
    return addressType.billing;
  } else {
    return addressType.shipping;
  }
}

export default countries;
